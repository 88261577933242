import React from 'react';

export const SinglePageWrapper = ({ children, ...rest }) => (
  <section className="section is-small" {...rest}>
    <div className="container is-max-desktop">{children}</div>
  </section>
);

export const ContentWithAside = ({ children }) => (
  <div className="columns">{children}</div>
);
export const Aside = ({ children }) => (
  <div className="column is-3">{children}</div>
);
export const Content = ({ children }) => (
  <div className="column is-9">{children}</div>
);

export const TextList = ({ title = '', data = [], className = '' }) => (
  <div className={className}>
    <p className="has-text-centered-mobile">
      <span className="has-text-weight-bold">{title} </span> <br />
    </p>
    <ul className="has-text-centered-mobile">
      {data.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>
          <span>{item}</span>
        </li>
      ))}
    </ul>
  </div>
);
