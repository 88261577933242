import * as React from 'react';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { useCities } from '@/store/hooks/cities';
import { CitySuggestion } from '@/store/cities';
import Filter from './filter';

interface CitiesFilterProps {
  required?: boolean;
  defaultValue?: string;
  color?: string;
}

const CitiesFilter: React.FunctionComponent<CitiesFilterProps> = ({
  required,
  defaultValue,
  color = `primary`,
}) => {
  const {
    all: suggestions,
    currentSuggestion,
    actions: { findSuggestions, setCurrentSuggestion },
  } = useCities();
  React.useEffect(() => {
    findSuggestions({ term: `` });
  }, []);

  return (
    <Filter
      color={color}
      icon={faMapMarkerAlt}
      itemsIcons={{
        _all: faMapMarkerAlt,
      }}
      downshift={{
        items: suggestions,
        initialInputValue: currentSuggestion?.value || currentSuggestion?.value,
        inputProps: {
          placeholder: `Où ?`,
          required,
          defaultValue,
        },
        itemToString: (city: CitySuggestion) => city.value,
        defaultHighlightedIndex: 0,
        initialHighlightedIndex: 0,
        onInputValueChange: ({ inputValue }) => {
          findSuggestions({ term: inputValue });
        },
        onSelectedItemChange: ({ selectedItem }) => {
          setCurrentSuggestion(selectedItem);
        },
        onEnterOrBlur: (value) => {
          setCurrentSuggestion(value);
        },
      }}
    />
  );
};

export default CitiesFilter;
