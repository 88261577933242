import React from 'react';
import useStore from '..';

import { Filters } from '../filters';
import { PractitionersSlice } from '../practitioners';

export interface UsePractitionersHook {
  all: PractitionersSlice['all'];
  suggestions: PractitionersSlice['suggestions'];
  currentSuggestion: PractitionersSlice['currentSuggestion'];
  currentSearch: PractitionersSlice['currentSearch'];
  filters: Filters;
  actions: {
    fetch: PractitionersSlice['fetch'];
    find: PractitionersSlice['find'];
    findSuggestions: PractitionersSlice['findSuggestions'];
    clear: PractitionersSlice['clear'];
    setCurrentSuggestion: PractitionersSlice['setCurrentSuggestion'];
    setCurrentSearch: PractitionersSlice['setCurrentSearch'];
  };
}

export const usePractitioners = (
  filters?: Filters | undefined,
): UsePractitionersHook => {
  const fetch = useStore((state) => state.practitioners.fetch);
  const findSuggestions = useStore(
    (state) => state.practitioners.findSuggestions,
  );
  const clear = useStore((state) => state.practitioners.clear);
  const all = useStore((state) => state.practitioners.all);
  const suggestions = useStore((state) => state.practitioners.suggestions);
  const currentSuggestion = useStore(
    (state) => state.practitioners.currentSuggestion,
  );
  const setCurrentSuggestion = useStore(
    (state) => state.practitioners.setCurrentSuggestion,
  );
  const currentSearch = useStore((state) => state.practitioners.currentSearch);
  const setCurrentSearch = useStore(
    (state) => state.practitioners.setCurrentSearch,
  );
  const find = useStore((state) => state.practitioners.find);
  const commonFilters = useStore((state) => state.filters.common);
  const practitionersFilters = useStore((state) => state.filters.practitioners);

  return {
    all,
    suggestions,
    currentSuggestion,
    currentSearch,
    filters: { common: commonFilters, practitioners: practitionersFilters },
    actions: {
      fetch,
      clear,
      findSuggestions,
      setCurrentSuggestion,
      find,
      setCurrentSearch,
    },
  };
};
