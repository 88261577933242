import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedin,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';

export const getSocialNetworkIconName = (url) => {
  if (url.match(/facebook/gi)) return faFacebookF;
  if (url.match(/twitter/gi)) return faTwitter;
  if (url.match(/instagram/gi)) return faInstagram;
  if (url.match(/linkedin/gi)) return faLinkedin;
  if (url.match(/youtube/gi)) return faYoutube;

  return faLink;
};
