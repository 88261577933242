import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faLocationArrow,
  faPhone,
  faAt,
  faGlobeEurope,
  faCalendar,
  faUsers,
  faUser,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { useFocus } from '@/store/hooks/focus';
import { buildUrlFromString } from '@/utils/url';
import { Link } from 'gatsby';
import { Activity } from '../../../../backend/src/models/activities.model';

interface ActivitySearchResultProps {
  activity: Activity;
  actions?: boolean;
  // profilePicture?: any;
  // focusFunctions: [number, React.Dispatch<React.SetStateAction<number>>];
  index?: number;
}

const ActivitySearchResult: React.FunctionComponent<
  ActivitySearchResultProps
> = ({ activity, actions = true, index = 0 }) => {
  const {
    actions: { setFocus },
  } = useFocus();
  return (
    // const [focus, setFocus] = focusFunctions;
    <div className={classNames(`box praticien mb-3`)}>
      <h2
        className="title is-5 is-size-6-mobile has-text-secondary"
        dangerouslySetInnerHTML={{
          __html: activity.title,
        }}
      />
      <p className="subtitle is-6 has-text-secondary">
        {activity.contact.organization}&nbsp;
      </p>
      {activity?.category?.title && (
        <p
          className="subtitle is-6 has-text-weight-bold mb-1"
          dangerouslySetInnerHTML={{
            __html: activity?.category?.title,
          }}
        />
      )}
      <p className="mb-5">{activity.description}</p>
      <ul>
        <li>
          <span className="icon-text is-flex-wrap-nowrap">
            <span className="icon has-text-secondary">
              <FontAwesomeIcon icon={faCalendar} />
            </span>
            <span className="has-text-weight-bold">
              {activity.timing.label &&
                activity.timing.label.replace(/\.$/, ``)}
              {` `}
              {activity.timing.hours}
            </span>
          </span>
        </li>
        <li>
          {(activity.location.address || activity.location.city) && (
            <p className="icon-text">
              <span className="icon has-text-secondary">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </span>
              <span className="has-text-weight-bold">
                {activity.location.address},&nbsp;
                <span
                  dangerouslySetInnerHTML={{
                    __html: activity.location.city,
                  }}
                />
              </span>
            </p>
          )}
        </li>
        <li>
          {(activity.contact.name || activity.contact.skill) && (
            <p className="mb-5 icon-text is-flex-wrap-nowrap">
              <span className="icon has-text-secondary">
                <FontAwesomeIcon icon={faUsers} />
              </span>
              <span className="has-text-weight-bold">
                {activity.contact.name}
                {activity.contact.skill}
              </span>
            </p>
          )}
        </li>
      </ul>

      <div className="columns is-mobile">
        <div className="column">
          <ul>
            <li>
              {activity.contact.email && (
                <a
                  href={`mailto:${activity.contact.email}`}
                  className="has-text-dark icon-text"
                >
                  <span className="icon has-text-secondary">
                    <FontAwesomeIcon icon={faAt} className="mr-1" />
                  </span>
                  <span>{activity.contact.email}</span>
                </a>
              )}
            </li>
            <li>
              {activity.contact.website && (
                <p>
                  <a
                    href={buildUrlFromString(activity.contact.website)}
                    target="_blank"
                    rel="noreferrer"
                    className="has-text-dar icon-text"
                  >
                    <span className="icon has-text-secondary">
                      <FontAwesomeIcon icon={faGlobeEurope} className="mr-1" />
                    </span>
                    <span>
                      {activity.contact.website.replace(
                        /^(https?:\/\/)?(www\.)?/,
                        ``,
                      )}
                    </span>
                  </a>
                </p>
              )}
            </li>
            <li>
              {activity.contact.phone && (
                <a
                  href={`tel:${activity.contact.phone}`}
                  target="_blank"
                  rel="noreferrer"
                  className="has-text-dark icon-text"
                >
                  <span className="icon has-text-secondary">
                    <FontAwesomeIcon icon={faPhone} className="mr-1" />
                  </span>
                  <span>{activity.contact.phone}</span>
                </a>
              )}
            </li>
          </ul>
        </div>
        <div className="column is-narrow is-flex is-justify-content-end is-align-items-end">
          {actions && (
            <>
              <button
                type="button"
                className="button is-small is-inverted is-secondary has-text-weight-bold mr-3 is-hidden-mobile"
                onClick={() => {
                  setFocus(index);
                }}
              >
                <span>Localiser</span>
                <span className="icon">
                  <FontAwesomeIcon icon={faLocationArrow} />
                </span>
              </button>
              {activity.linkedPractitioners && activity.linkedPractitioners[0] && (
                <Link
                  to={`${activity?.linkedPractitioners[0].meta.websiteSlug}#content`}
                  className="button is-small is-secondary has-text-weight-bold mr-3 is-hidden-mobile"
                >
                  <span>Profil complet</span>
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default ActivitySearchResult;
