import React from 'react';
import Hero from '../hero';
import PractitionersFilters from './filters';

const PractitionersSearchSection: React.FunctionComponent = () => (
  <Hero
    title="Trouver un thérapeute, spécialiste ou professionnel près de chez vous"
    subTitle="Utilisez les champs de recherche pour parcourir les catégories, praticiens et selectionnez votre ville."
    filters={<PractitionersFilters />}
  />
);

export default PractitionersSearchSection;
