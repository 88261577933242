import shallow from 'zustand/shallow';
import useStore from '..';
import { CitiesSlice } from '../cities';

export interface UseCitiesHook {
  all: CitiesSlice['all'];
  current: CitiesSlice['current'];
  currentSuggestion: CitiesSlice['currentSuggestion'];
  actions: {
    findSuggestions: CitiesSlice['findSuggestions'];
    setCurrentSuggestion: CitiesSlice['setCurrentSuggestion'];
  };
}

export const useCities = (): UseCitiesHook => {
  const {
    all,
    current,
    currentSuggestion,
    findSuggestions,
    setCurrentSuggestion,
  } = useStore((state) => state.cities, shallow);

  return {
    all,
    current,
    currentSuggestion,
    actions: { findSuggestions, setCurrentSuggestion },
  };
};
