import ActivitySearchResult from '@/components/activities/list-item';
import Map from '@/components/map';
import PractitionersSearchSection from '@/components/practitioners/search-section';
import Seo from '@/components/seo';
import { useFocus } from '@/store/hooks/focus';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, Link } from 'gatsby';
import { getImage, StaticImage, GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../components/layout';
import {
  Aside,
  Content,
  ContentWithAside,
  SinglePageWrapper,
  TextList,
} from './template-components/layout';
import { getSocialNetworkIconName } from './template-components/utils';

const PractitionerTemplate = ({
  data,
  pageContext: { practitioner, practitionerActivities },
}) => {
  // data
  const {
    title,
    location,
    content: {
      price,
      practiceSince,
      consultationsPlaces,
      consultationsTypes,
      longDescription,
      description,
      timetable,
    },
    contact,
    otherCategories,
  } = practitioner;

  const currentCategory = {
    current: true,
    url: practitioner.meta.websiteSlug,
    category: practitioner.category,
  };
  const allCategories = [currentCategory, ...otherCategories].sort((a, b) =>
    a.category.title.localeCompare(b.category.title),
  );
  useFocus(0);

  // Profile pic

  const profilePicImage =
    data.allAirtable.edges.length > 0 &&
    data.allAirtable.edges[0].node.data.Photo_profil &&
    data.allAirtable.edges[0].node.data.Photo_profil.localFiles &&
    data.allAirtable.edges[0].node.data.Photo_profil.localFiles.length > 0
      ? getImage(data.allAirtable.edges[0].node.data.Photo_profil.localFiles[0])
      : undefined;

  // Cabinet pic
  const cabinetPictures =
    // fileMakerId === practitioner.fmId &&
    data.allAirtable.edges.length > 0 &&
    data.allAirtable.edges[0].node.data.Photos_de_votre_cabinet &&
    data.allAirtable.edges[0].node.data.Photos_de_votre_cabinet.localFiles &&
    data.allAirtable.edges[0].node.data.Photos_de_votre_cabinet.localFiles
      .length > 0
      ? data.allAirtable.edges[0].node.data.Photos_de_votre_cabinet.localFiles.map(
          (localFile) => getImage(localFile),
        )
      : undefined;

  const profilePictureAlt = `${title} ${practitioner.category.title} ${
    (location && location.city) || ''
  }`;
  return (
    <Layout>
      <Seo
        title={`${title} - ${currentCategory.category.title}`}
        description={description}
      />
      <PractitionersSearchSection />
      <SinglePageWrapper id="content">
        <ContentWithAside>
          <Aside>
            <h1 className="title has-text-primary has-text-centered-mobile is-hidden-tablet">
              {title}
            </h1>
            <div className="has-text-centered-mobile mb-5">
              <figure className="image is-inline-block">
                {profilePicImage ? (
                  <GatsbyImage
                    image={profilePicImage}
                    alt={profilePictureAlt}
                  />
                ) : (
                  <StaticImage
                    src="../assets/images/person-placeholder-mini.png"
                    imgClassName="is-rounded"
                    alt={profilePictureAlt}
                  />
                )}
              </figure>
            </div>
            {practiceSince && (
              <TextList
                title="Pratique depuis"
                data={[practiceSince.substring(0, 4)]}
                className="mb-5"
              />
            )}
            {contact && contact.socialNetworks && contact.socialNetworks[0] && (
              <>
                <p className="has-text-centered-mobile">
                  <span className="has-text-weight-bold">Réseaux sociaux </span>{' '}
                  <br />
                </p>
                <ul className="mb-5 has-text-centered-mobile">
                  {Object.keys(contact.socialNetworks).map((key) => (
                    <li className="is-inline-block mr-2" key={key}>
                      <a
                        href={contact.socialNetworks[key]}
                        target="__blank"
                        className="icon"
                      >
                        <FontAwesomeIcon
                          icon={getSocialNetworkIconName(
                            contact.socialNetworks[key],
                          )}
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              </>
            )}
            {consultationsPlaces && consultationsPlaces[0] && (
              <TextList
                title="Lieux de consultation"
                data={consultationsPlaces}
                className="mb-5"
              />
            )}
            {consultationsTypes && consultationsTypes[0] && (
              <TextList
                title="Types de consultation"
                data={consultationsTypes}
                className="mb-5"
              />
            )}
            {/* practitionerActivities && practitionerActivities[0] && (
              <TextList
                title="Evénements à venir"
                data={practitionerActivities.map((activity) => (
                  <div className="mt-3">
                    <ActivityThumbnail activity={activity} key={activity._id} />
                  </div>
                ))}
              />
            ) */}
          </Aside>
          <Content>
            <h1 className="title has-text-primary is-hidden-mobile">{title}</h1>
            <div className="tabs is-boxed">
              <ul>
                {allCategories.map((category) => (
                  <li
                    key={category.category.code}
                    className={category.current ? 'is-active' : ''}
                  >
                    <Link to={`${category.url}#content`}>
                      {/* TODO pitbi sends correct url */}
                      <span className="has-text-primary has-text-weight-bold">
                        {category.category.title}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <TextList
              title="Informations de contact"
              className="box"
              data={[
                contact && contact.email && (
                  <a href={`mailto:${contact.email}`}>{contact.email}</a>
                ),
                contact && contact.phone && (
                  <a href={`tel:${contact.phone}`}>{contact.phone}</a>
                ),
                contact && contact.website && (
                  <a
                    href={
                      contact.website.match(/^https?/)
                        ? contact.website
                        : `http://${contact.website}`
                    }
                    target="__blank"
                  >
                    {contact.website}
                  </a>
                ),
              ].filter((i) => i)}
            />
            <TextList
              title="Adresses de consultation"
              className="box"
              data={[
                <span>
                  {location && location.address
                    ? `${location.address}, `
                    : undefined}
                  {location && location.zip ? `${location.zip}, ` : undefined}
                  {location && location.city ? `${location.city}, ` : undefined}
                </span>,
                ...consultationsPlaces,
              ]}
            />
            <div className="map mb-5" id="list-items">
              <Map
                height={250}
                markers={[
                  {
                    position: location && [location.lat, location.lon],
                    text: title,
                  },
                ]}
              />
            </div>
            {(longDescription || description) && (
              <div className="pt-5 mb-5">
                <h3 className="title is-4 has-text-centered has-text-weight-bold has-text-primary">
                  À propos
                </h3>
                <p
                  style={{ whiteSpace: 'pre-line' }}
                  className="has-text-justified"
                >
                  {longDescription || description}
                </p>
              </div>
            )}
            {practitionerActivities && practitionerActivities[0] && (
              <div className="pt-5 mb-5">
                <h3 className="title is-4 has-text-centered has-text-weight-bold has-text-primary">
                  Evénements à venir
                </h3>
                {practitionerActivities.map((activity) => (
                  <ActivitySearchResult activity={activity} actions={false} />
                ))}
              </div>
            )}
            {cabinetPictures && cabinetPictures.length && (
              <div
                className="columns my-5 is-mobile is-multiline"
                style={{ justifyContent: 'center' }}
              >
                {cabinetPictures.map((picture) => (
                  <div
                    className="column has-text-centered is-4-mobile"
                    key={picture.id}
                  >
                    <figure className="image">
                      <GatsbyImage image={picture} />
                    </figure>
                  </div>
                ))}
              </div>
            )}
            <div className="columns my-5 is-centered">
              {price && (
                <div className="column is-6">
                  <div className="box">
                    <p className="has-text-weight-bold mb-3">Tarifs</p>
                    <table className="table is-hoverable">
                      <tbody>
                        {price.split('\n').map((lineOfText) => (
                          <tr key={lineOfText}>
                            <td>{lineOfText}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              {timetable && timetable[0] && (
                <div className="column is-6">
                  <div className="box">
                    <p>
                      <span className="has-text-weight-bold">Horaires</span>{' '}
                      <br />
                    </p>
                    <table className="table is-hoverable">
                      <thead>
                        <tr>
                          <th>Jour</th>
                          <th className="has-text-centered">Matin</th>
                          <th className="has-text-centered">Après midi</th>
                          <th className="has-text-centered">Soir</th>
                        </tr>
                      </thead>
                      <tbody>
                        {[
                          'Lundi',
                          'Mardi',
                          'Mercredi',
                          'Jeudi',
                          'Vendredi',
                          'Samedi',
                          'Dimanche',
                        ].map((day) => (
                          <tr key={day}>
                            <td>{day}</td>
                            {['Matin', 'Après Midi', 'Soir'].map((period) => (
                              <td className="has-text-centered" key={period}>
                                <FontAwesomeIcon
                                  className={
                                    !timetable.includes(`${day} ${period}`)
                                      ? 'has-text-primary-light'
                                      : undefined
                                  }
                                  icon={
                                    timetable.includes(`${day} ${period}`)
                                      ? faCheck
                                      : faTimes
                                  }
                                />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
            {practitioner.accompaniments_domains &&
              practitioner.accompaniments_domains.length > 0 && (
                <div className="box">
                  <div className="tags">
                    {practitioner.accompaniments_domains.map((domain) => (
                      <span
                        className="tag is-primary is-light is-medium"
                        key={domain}
                      >
                        {domain}
                      </span>
                    ))}
                  </div>
                </div>
              )}
          </Content>
        </ContentWithAside>
      </SinglePageWrapper>
    </Layout>
  );
};

export default PractitionerTemplate;

export const query = graphql`
  query AirtablePicsQuery($fileMakerId: Int) {
    allAirtable(filter: { data: { FILEMAKER_ID: { eq: $fileMakerId } } }) {
      edges {
        node {
          data {
            Photo_profil {
              localFiles {
                childImageSharp {
                  gatsbyImageData(width: 800)
                }
              }
            }
            Photos_de_votre_cabinet {
              localFiles {
                childImageSharp {
                  gatsbyImageData(width: 150, aspectRatio: 1)
                }
              }
            }
          }
        }
      }
    }
  }
`;
