import useStore from '@/store';
import { CitySuggestion } from '@/store/cities';
import { useCities } from '@/store/hooks/cities';
import { usePractitioners } from '@/store/hooks/practitioners';
import { PractitionerSuggestion } from '@/store/practitioners';
import { faTag, faUser } from '@fortawesome/free-solid-svg-icons';
import { navigate } from 'gatsby';
import React from 'react';
import CitiesFilter from '../search/filters/cities-filter';
import FiltersSubmitButton from '../search/filters/submit-button';
import TextFilter from '../search/filters/text-filter';

interface PractitionerSearchQueryParams {
  search?: string;
  near?: [CitySuggestion['longitude'], CitySuggestion['latitude']];
  city?: string;
}

const PractitionersFilters: React.FunctionComponent = () => {
  // Hooks
  const {
    suggestions,
    currentSuggestion: currentPractitionerSuggestion,
    currentSearch: currentPractitionerSearch,
    actions: { find, setCurrentSuggestion, setCurrentSearch, findSuggestions },
  } = usePractitioners();
  const { currentSuggestion: currentCitySuggestion } = useCities();

  const findArticles = useStore((state) => state.articles.find);

  // Effects
  const fetch = React.useCallback(() => {
    const query: PractitionerSearchQueryParams = {};
    if (currentPractitionerSuggestion) {
      query[currentPractitionerSuggestion.field] =
        currentPractitionerSuggestion.value;
    } else if (currentPractitionerSearch) {
      query.search = currentPractitionerSearch;
    }
    if (currentCitySuggestion) {
      query.near = [
        currentCitySuggestion.longitude,
        currentCitySuggestion.latitude,
      ];
      query.city = currentCitySuggestion.value;
    }
    find(query);
    findArticles({
      search: currentPractitionerSuggestion
        ? currentPractitionerSuggestion.value
        : currentPractitionerSearch,
      $limit: 3,
    });
  }, [
    currentPractitionerSuggestion,
    currentCitySuggestion,
    currentPractitionerSearch,
  ]);

  React.useEffect(
    () => fetch(),
    [
      currentPractitionerSuggestion,
      currentCitySuggestion,
      currentPractitionerSearch,
    ],
  );

  React.useEffect(() => {
    findSuggestions();
  }, []);

  const formRef = React.useRef<HTMLFormElement>();
  return (
    <div className="has-text-centered">
      <form
        ref={formRef}
        onSubmit={(e) => {
          formRef.current.reportValidity();
          e.preventDefault();
          navigate(`/therapeutes-et-professionnels/`);
        }}
      >
        <div className="columns">
          <div className="column">
            <TextFilter
              suggestions={suggestions}
              currentValue={currentPractitionerSuggestion?.value}
              fetchSuggestions={(term) => findSuggestions({ term })}
              onSelect={(suggestion: PractitionerSuggestion) =>
                setCurrentSuggestion(suggestion)
              }
              onEnterOrBlur={(value: string | PractitionerSuggestion) => {
                if (typeof value === `string`) setCurrentSearch(value);
                else if (value.value) setCurrentSuggestion(value);
              }}
              placeholder="Nom, catégorie, mot-clé..."
              itemIcons={{
                title: faUser,
                'category.title': faTag,
              }}
            />
          </div>
          <div className="column">
            <CitiesFilter />
          </div>
          <div className="column is-narrow">
            <FiltersSubmitButton onClick={fetch} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default PractitionersFilters;
